@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

:root {
  --primary-color: #000;
  --secondary-color: #1a1a1a;
  --accent-color: #46aaa0;
}

body {
  font-family: "Montserrat", sans-serif;
  width: 100%;
  height: 100vh;
  color: white;
  background-color: var(--primary-color);
  overflow-x: hidden;
}

/* Starting animation "MARCO SU" */
#logo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 0 5%;
  transform: translate(-50%, -50%);
  animation: fadeToTopLeft 2s ease forwards 4s;
}

#logo path:nth-child(2) {
  stroke-dasharray: 780px;
  stroke-dashoffset: 780px;
  animation: line-anim 4s ease forwards;
}

#logo path:nth-child(3) {
  stroke-dasharray: 464px;
  stroke-dashoffset: 464px;
  animation: line-anim 4s ease forwards;
}
#logo path:nth-child(4) {
  stroke-dasharray: 495px;
  stroke-dashoffset: 495px;
  animation: line-anim 4s ease forwards;
}
#logo path:nth-child(5) {
  stroke-dasharray: 510px;
  stroke-dashoffset: 510px;
  animation: line-anim 4s ease forwards;
}
#logo path:nth-child(6) {
  stroke-dasharray: 636px;
  stroke-dashoffset: 636px;
  animation: line-anim 4s ease forwards;
}
#logo path:nth-child(7) {
  stroke-dasharray: 605px;
  stroke-dashoffset: 605px;
  animation: line-anim 4s ease forwards;
}
#logo path:nth-child(8) {
  stroke-dasharray: 500px;
  stroke-dashoffset: 500px;
  animation: line-anim 4s ease forwards;
}

@keyframes line-anim {
  50% {
    stroke-dashoffset: 0;
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    fill: white;
  }
}

@keyframes fadeToTopLeft {
  to {
    top: -10px;
    left: 5%;
    height: 70px;
    width: 20px;
    opacity: 0;
  }
}

/* Navigation Bar */
.navigation {
  position: fixed;
  height: 6rem;
  width: 100%;
  top: -100px;
  z-index: 10;
  background: var(--primary-color);
  animation: slide 2s linear forwards 5s;
}

@keyframes slide {
  to {
    top: 0;
  }
}

nav {
  position: absolute;
  top: 10px;
  right: 5%;
  width: fit-content;
  height: 50px;
  overflow: hidden;
  margin: 20px 20px 0 0;
  font-size: 1.5rem;
}

nav .nav-link {
  color: white;
  text-decoration: none;
  font-weight: bold;
  outline: none;
  margin-left: 40px;
  border: none;
  transition: border 2s ease;
  cursor: pointer;
}

.nav-link:hover {
  opacity: 0.9;
}

.nav-link .rocketUp {
  animation: rocketOff 0.5s ease-in forwards;
}
.nav-link .rocketDown {
  animation: rocketLand 0.5s ease-out forwards;
}

@keyframes rocketOff {
  0% {
    background: linear-gradient(45deg, red 0%, transparent 40%);
  }
  70% {
    background: linear-gradient(45deg, red 0%, transparent 40%);
    transform: none;
  }
  100% {
    transform: translate(50px, -50px);
  }
}
@keyframes rocketLand {
  0% {
    transform: translate(50px, -50px);
  }
  100% {
    transform: none;
  }
}

.navigation .nav_button {
  position: absolute;
  top: 0%;
  right: 0%;
  background-color: var(--accent-color);
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  height: 6rem;
  width: 6rem;
  cursor: pointer;
}

.navigation .nav_button svg {
  position: absolute;
}

.logo {
  position: absolute;
  height: 4rem;
  top: 15px;
  left: 5%;
  user-select: none;
  animation: popout 6s ease-in;
}

.logo:hover {
  opacity: 0.9;
  cursor: pointer;
}

@keyframes popout {
  0% {
    top: -100px;
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hamburger ul {
  position: fixed;
  top: 6rem;
  width: 100%;
  list-style: none;
  font-size: 1.5rem;
  display: none;
  z-index: 9;
  transition: 0.7s ease-out;
  border-top: 1px solid white;
}

.hamburger ul li {
  background: var(--primary-color);
  border-bottom: 1px solid white;
  letter-spacing: 2px;
  line-height: 50px;
  cursor: pointer;
  overflow: hidden;
  user-select: none;
  white-space: nowrap;
}

.hamburger ul li .nav-link {
  display: block;
  padding-left: 30px;
}

.hamburger ul li svg {
  margin-right: 20px;
}

/* HOME */
.home {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: var(--primary-color);
}

.moon {
  height: 100vh;
  position: absolute;
  top: -100%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: toTheMoon 2s ease forwards 4s;
}

@keyframes toTheMoon {
  to {
    top: 55%;
  }
}

.home h1 {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  text-align: center;
  color: var(--primary-color);
  text-shadow: 0 0 10px white;
  animation: popout 6s ease-in;
}

/* ALL SECTIONS */
section {
  width: 100vw;
  padding: 5% 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

section:nth-child(even) {
  background: var(--secondary-color);
}

section h1 {
  font-size: 2rem;
}

/* About */
#about .about-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  flex-wrap: wrap;
}

.about-container .about-text {
  width: 600px;
  margin-right: 5%;
}

.about-container .about-text span {
  font-size: 1.5rem;
  line-height: 3rem;
  letter-spacing: 2px;
  text-align: justify;
}

.about-container .about-text p {
  font-size: 1rem;
  line-height: 30px;
  letter-spacing: 2px;
  text-align: justify;
}

.about-container img {
  width: calc(0.3 * 100vw);
  padding: 5%;
}

/* Skills */
#skills h1 {
  font-size: 2rem;
}

.skills-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
}

.skills-container .skillRow {
  height: 2rem;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.skills-container .skillRow h4 {
  font-size: 1.2rem;
  width: 15%;
  text-align: right;
}

.skills-container .skillRow .skillBar {
  width: 70%;
  border: 1px solid var(--accent-color);
  border-radius: 15px;
  margin: 0 20px;
}

.skills-container .skillRow .skillBar .skillFill {
  height: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 12px;
  padding-right: 10px;
  background: var(--accent-color);
  transition: width 2s;
}

/* Portfolio */
.portfolio-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin-top: 50px;
}

.portfolio-container .project {
  position: relative;
  height: 250px;
  width: 350px;
  overflow: hidden;
  border-radius: 15px;
  margin: 0 0 3rem 10px;
}

.portfolio-container .project img {
  height: 100%;
  width: 100%;
  transition: 0.3s ease-in;
}
.portfolio-container .project:hover img {
  transform: scale(1.1);
}

.portfolio-container .project .launch {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.3s;
}

.portfolio-container .project .launch a {
  display: inline-block;
  color: white;
  text-decoration: none;
  border: 1px solid var(--accent-color);
  padding: 10px 20px;
}

.portfolio-container .project .launch span {
  position: absolute;
  bottom: 50px;
}

.portfolio-container .project:hover .launch {
  opacity: 1;
}

#portfolio h3 a {
  color: var(--accent-color);
}

/* Contact */
#contact {
  width: 100vw;
  padding: 3% 10%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--accent-color);
}

.footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 20px;
  background-color: var(--accent-color);
}

.footer .email span:hover {
  color: var(--primary-color);
}

.footer .email p {
  font-size: 4rem;
  cursor: default;
  font-weight: normal;
  padding-bottom: 10px;
}

.footer .email a {
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: underline;
  color: var(--primary-color);
}

.footer .email a:hover {
  color: white;
}

.footer .socials {
  display: flex;
  align-items: flex-end;
}

.footer .socials img {
  height: 3rem;
  width: 3rem;
  margin-left: 1rem;
}

.contact p {
  text-align: center;
  font-weight: bold;
}

@media (max-width: 800px) {
  html {
    font-size: 10px;
  }

  nav {
    display: none;
  }

  .navigation .nav_button {
    display: flex;
  }
  .hamburger ul {
    display: block;
  }

  #skills {
    padding: 5% 2%;
  }

  #contact {
    padding: 3% 5%;
  }

  /* .skills-container .skillRow h4 {
    width: 15%;
  }

  .skills-container .skillRow .skillBar {
    width: 70%;
  } */
}
